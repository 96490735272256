<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加终检人</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging"  :header-cell-style="{textAlign: 'center'}" :cell-style="{ textAlign: 'center' }">
          <en-table-column header-align="center" label="操作" width="200">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['FinalInspectorDto'] }">
              <button-delete :method="table.operation.delete.click" :params="row"> 删除 </button-delete>
            </template>
          </en-table-column>
          <en-table-column header-align="center" label="终检人" prop="inspector.name"> </en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>
  <en-drawer v-model="detail.visible" title="添加终检人">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="终检人" prop="inspector">
        <select-maintain
          v-model="detail.form.data.inspector"
          :ajax="{
            action: 'GET /enocloud/common/user',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        },
        edit: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },

      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/finalinspector',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { pagingEnabled: false }
            }
          },
          delete: {
            action: 'DELETE /enocloud/common/finalinspector/:inspectorId',
            loading: true
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['FinalInspectorDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            data: {} as EnocloudCommonDefinitions['FinalInspectorDto'],
            is: 'form',
            ajax: {
              submit: {
                action: 'POST /enocloud/common/finalinspector',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: { inspector: [{ required: true, message: '请输入终检人姓名' }] }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form.submit()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
